import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { StaticQuery, graphql } from "gatsby";

function dpConveningFaculty() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMdx(
            filter: {
              frontmatter: { tags: { eq: "dp_wi2022_convening" } }
              fileAbsolutePath: { regex: "/(faculty-and-staff)/" }
            }
          ) {
            nodes {
              frontmatter {
                title
                headshot
              }
              body
            }
          }
        }
      `}
      render={(data) => (
        <Row className="convening-faculty">
          <h1>Convening Faculty</h1>
          {data.allMdx.nodes.map((node) => (
            <Col xs={11} md={4} lg={3} className="mb-3 mb-md-0 p-4 text-center">
              <img
                src={node.frontmatter.headshot}
                alt={`Headshot of ${node.frontmatter.title}`}
                className="img-fluid img-rounded"
              />
            </Col>
          ))}
          {data.allMdx.nodes.map((node) => (
            <Col xs={11} md={8} lg={9} className="p-3">
              <h5>{node.frontmatter.title}</h5>
              <MDXRenderer>{node.body}</MDXRenderer>
            </Col>
          ))}
        </Row>
      )}
    />
  );
}

export default dpConveningFaculty;
